import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useUnknown from "lib/hooks/use-unknown";
import Links from "./links";

function DeepDive({id}) {
  const params = useParams();
  const unknown = useUnknown(id || params.id);

  if(!unknown) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>Deep Dive</h1>
        <div className="custom-html">
          <Content className="mb-4" html={unknown.deep_dive || "There is no deep dive yet for this unknown"} />
        </div>
        <Links />
      </div>
    </div>
  );
}

DeepDive.defaultProps = {id: null};
DeepDive.propTypes = {id: PropTypes.number};

export default DeepDive;
