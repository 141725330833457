import app from "app/reducers/app";
import unknown from "app/reducers/unknown";
import category from "app/reducers/category";
import notifications from "app/reducers/notifications";
import page from "app/reducers/page";
import pages from "app/reducers/pages";
import sections from "app/reducers/sections";
import tag from "app/reducers/tag";
import test from "app/reducers/test";
import user from "app/reducers/user";
import combineReducers from "lib/combine-reducers";

export default combineReducers({
  app,
  unknown,
  category,
  notifications,
  page,
  pages,
  sections,
  tag,
  test,
  user
});
