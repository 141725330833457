import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getTags} from "app/requests/admin/tags";
import {get as getTest} from "app/requests/admin/tests";
import ContentEditor from "components/helpers/form/content-editor";
import Notification from "components/helpers/notification";
import Loading from "components/pages/loading";
import Answers from "./form/answers";

const useTags = ({testID}) => {
  const [tags, setTags] = useState(null);
  const [test, setTest] = useState(null);
  const [unscopedTags, setUnscopedTags] = useState(null);

  useEffect(() => {
    getTags().then((data) => setUnscopedTags(data.tags));
  }, []);

  useEffect(() => {
    getTest(testID).then((data) => setTest(data.test));
  }, [testID]);

  useEffect(() => {
    if(!test) { return; }
    if(!unscopedTags) { return; }

    setTags(unscopedTags.filter((tag) => tag.category_id === test.category_id));
  }, [test, unscopedTags]);

  return tags;
};

function AdminTestQuestionsForm({onChange, onSubmit, value}) {
  const tags = useTags({testID: value("test_id")});

  if(!tags) { return <Loading />; }

  const onAddAnswer = () => {
    const answers = value("answers") || [];

    onChange({target: {name: "answers", value: [...answers, {}]}});
  };

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="record-content">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-explanation">Explanation</label>
          <ContentEditor
            id="record-explanation"
            name="explanation"
            onChange={onChange}
            value={value("explanation")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Name"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-rank">Rank</label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-tag-id">Tag</label>
          {tags.length === 0 ? (
            <Notification type="error">There are no tags available. You must <a href="/admin/tags">create one</a> before creating a test question.</Notification>
          ) : (
            <select
              id="record-tag-id"
              name="tag_id"
              onChange={onChange}
              required={true}
              value={value("tag_id")}
            >
              <option value="">Select Tag</option>
              {tags.map(({id, name}) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
          )}
        </div>
        <Answers onChange={onChange} value={value("answers") || []} />
        <div className="flex items-center justify-between">
          <button onClick={onAddAnswer} type="button" className="btn btn-green">Add Answer</button>
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminTestQuestionsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminTestQuestionsForm;
