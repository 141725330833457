import PropTypes from "prop-types";
import {last} from "lib/ext/array";

const lastElementEmpty = (array) => Object.keys(last(array) || {}).length === 0;

function Fields({fields, id: _id, index, name, onChange, select, values}) {
  const id = index === 0 ? _id : `${_id}-${index}`;
  const selectedValues = values.map((value) => value[select.name])
    .filter(Boolean).map((value) => value.toString());
  const value = values[index] || {};
  const onFieldChange = ({target: {name: fieldName, type, value: fieldValue}}) => {
    const updatedValues = [...values];
    const updatedValue = {...value, [fieldName]: fieldValue === "" ? null : fieldValue};

    (fieldName === select.name && fieldValue === "")
      ? updatedValues.splice(index, 1)
      : updatedValues.splice(index, 1, updatedValue);

    if(lastElementEmpty(updatedValues)) { updatedValues.splice(-1); }

    onChange({target: {name, type, value: updatedValues}});
  };
  const options = select.options.map((option) => {
    if(typeof option === "object" && option !== null) { return option; }

    return {name: option, value: option};
  }).filter((option) => {
    if(!selectedValues.includes(option.value)) { return true; }

    return option.value === `${value[select.name]}`;
  });

  return (
    <div className="flex max-w-screen-md mb-4 mx-auto space-x-2 md:mx-[-50%] md:w-screen">
      <select id={id} name={select.name} onChange={onFieldChange} value={(value[select.name] || "").toString()}>
        <option value="">{select.placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.name}</option>
        ))}
      </select>
      {fields.map((field) => (
        <input
          key={field.name}
          id={`${id}-${field.name}`}
          name={field.name}
          onChange={onFieldChange}
          placeholder={field.placeholder}
          type={field.type}
          value={value[field.name] || ""}
        />
      ))}
    </div>
  );
}

Fields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  select: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        }),
        PropTypes.string
      ]).isRequired
    ).isRequired,
    placeholder: PropTypes.string.isRequired
  }).isRequired,
  values: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default Fields;
