import headers from "app/requests/headers";

export const get = (id) => (
  fetch(`/api/public/cases/${id}`, {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);

export const getRandom = () => (
  fetch("/api/public/cases/random", {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);
