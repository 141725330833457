import {SET_UNKNOWN} from "app/actions/unknown";

export default function unknown(state, action) {
  switch(action.type) {
    case SET_UNKNOWN:
      return action.unknown ? {...action.unknown} : null;
    default:
      return state;
  }
}
