import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setTest} from "app/actions/test";
import {get as getTest} from "app/requests/public/tests";
import {get as getResponse, update as updateResponse} from "app/requests/public/test-responses";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import Results from "./results";
import User from "./user";

// TODO: After answered, show explanation
export default function TestsShow() {
  const [{test, user}, dispatch] = useAppContext();
  const {id} = useParams();
  const [answerID, setAnswerID] = useState(null);
  const [responses, setResponses] = useState(null);
  const [testResponse, setTestResponse] = useState(null);

  useEffect(() => {
    if(test && test.id === id) { return; }

    getTest(id).then((data) => dispatch(setTest(data.test)));
  }, [id]);

  useEffect(() => {
    if(!test) { return; }
    if(!user) { return; }

    getResponse(id).then((data) => setTestResponse(data.test_response));
  }, [test, user]);

  useEffect(() => {
    if(!testResponse) { return; }
    if(testResponse.completed) { return; }

    const userResponses = testResponse.question_responses;
    const questionResponses = test.questions.map((question) => {
      const response = userResponses.find((r) => r.question_id === question.id);

      return response || {question_id: question.id};
    });

    setResponses(questionResponses);
  }, [testResponse]);

  useEffect(() => {
    if(!responses) { return; }

    const question = responses.find((response) => !response.answer_id);
    if(question) { return; }

    const record = {question_responses_attributes: responses};

    updateResponse(id, {test_response: record}).then((data) => {
      if(data.message) {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "error"
        }));
      }

      setTestResponse(data.test_response);
    });
  }, [responses]);

  if(testResponse && testResponse.completed) { return <Results testResponse={testResponse} />; }
  if(!user) { return <User />; }
  if(!responses) { return <Loading />; }
  if(!test) { return <Loading />; }

  const questionIndex = responses.findIndex((response) => !response.answer_id);
  const question = test.questions[questionIndex];

  if(!question) { return <Loading />; }

  const onResponse = () => {
    if(!answerID) { return; }

    const updatedResponses = [...responses];
    updatedResponses[questionIndex].answer_id = answerID;

    setAnswerID(null);
    setResponses(updatedResponses);
  };

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>Question {questionIndex + 1}</h1>
        {question.content && (
          <div className="custom-html">
            <Content className="mb-4" html={question.content} />
          </div>
        )}
        <div className="gap-5 grid my-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {question.answers.map((answer) => {
            const selected = answer.id === answerID;

            return (
              <div key={answer.id} className={`border-4 border-${selected ? "blue" : "gray"} flex flex-col justify-between p-2`}>
                <div className="custom-html">
                  <Content className="mb-4" html={answer.content} />
                </div>
                <div className="text-center">
                  <button className={`btn btn-${selected ? "blue" : "gray"}`} onClick={() => setAnswerID(answer.id)} type="button">Select</button>
                </div>
              </div>
            );
          })}
        </div>
        <button className="btn btn-green" onClick={onResponse} type="button">Submit</button>
      </div>
    </div>
  );
}
