import {Link} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";

export default function List() {
  const [{category}] = useAppContext();
  const prefix = "Emergency Ultrasound Unknown";

  return (
    <div className="divide-y inline-block">
      {category.cases.map((record, index) => (
        <div key={record.id} className="p-3">
          <Link to={`/unknowns/${record.id}/question`}>
            {category.display === "Name" ? record.name : `${prefix} - ${index + 1}`}
          </Link>
        </div>
      ))}
    </div>
  );
}
