import {SET_PAGE} from "app/actions/page";

export default function page(state, action) {
  switch(action.type) {
    case SET_PAGE:
      return action.page ? {...action.page} : null;
    default:
      return state;
  }
}
