import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {createNotification} from "app/actions/notifications";
import {subscribe, unsubscribe} from "app/requests/public/comments";
import useAppContext from "lib/hooks/use-app-context";

function Subscribe({record}) {
  const [{user}, dispatch] = useAppContext();
  const [subscribed, setSubscribed] = useState(null);

  useEffect(() => {
    if(!record || !user) { return; }

    const notification = user.comment_notifications
      .filter(({comment_notificationable_type: type}) => type === record.type)
      .find(({comment_notificationable_id: id}) => id === record.id);

    setSubscribed(!!notification);
  }, [record, user]);

  if(!record || !user) { return null; }

  const onSubscribe = () => {
    subscribe({id: record.id, type: record.type}).then(({message: content, success}) => {
      dispatch(createNotification({content, type: success ? "success" : "danger"}));
      setSubscribed(success);
    });
  };
  const onUnsubscribe = () => {
    unsubscribe({id: record.id, type: record.type}).then(({message: content, success}) => {
      dispatch(createNotification({content, type: "danger"}));
      setSubscribed(!success);
    });
  };

  return (
    <div className="flex justify-end mt-1">
      <button className="text-sm" onClick={subscribed ? onUnsubscribe : onSubscribe} type="button">
        {subscribed ? "unsubscribe" : "subscribe"} to comments
      </button>
    </div>
  );
}

Subscribe.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default Subscribe;
