import {useEffect, useState} from "react";
import {getAll as getRecords} from "app/requests/admin/tests";
import Loading from "components/pages/loading";
import Row from "./row";

export default function AdminTestsList() {
  const [records, setRecords] = useState(null);
  const onDestroy = (id) => {
    setRecords(records.filter((record) => record.id !== id));
  };

  useEffect(() => {
    getRecords().then((data) => setRecords(data.tests));
  }, []);

  if(!records) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">Tests</h1>
        <div className="text-right mb-1">
          <a className="btn btn-outline-black btn-sm" href="/admin/tests/new">New Test</a>
        </div>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th>Admin</th>
                <th>Name</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <Row key={record.id} onDestroy={onDestroy} record={record} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
