import {useEffect} from "react";
import {Link} from "react-router-dom";
import {setCategory} from "app/actions/category";
import {get as getCategory} from "app/requests/public/categories";
import Content from "components/helpers/action-text/content";
import DeepDive from "components/helpers/deep-dive";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";

export default function Category() {
  const [{category, page}, dispatch] = useAppContext();

  useEffect(() => {
    getCategory(page.category_id).then((data) => dispatch(setCategory(data.category)));
  }, [page.category_id]);

  if(!category) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>{category.name}</h1>
        <div className="custom-html">
          <Content className="mb-4" html={page.content} />
        </div>
        <DeepDive content={page.deep_dive} />
        <div className="custom-html">
          <div className="divide-y inline-block">
            {category.tags.map((record) => (
              <div key={record.id} className="p-3">
                <Link to={`/tags/${record.id}`}>{record.name}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
