import {NavLink} from "react-router-dom";

export default function AdminNavbar() {
  const isCases = (match, location) => {
    if(match) { return true; }

    const routes = ["categories", "tags"].map((page) => `/admin/${page}`);
    return routes.some((route) => location.pathname.startsWith(route));
  };

  return (
    <nav className="bg-dark bg-green">
      <div className="container">
        <div className="flex items-center justify-end">
          <div>
            <NavLink className="mx-2" isActive={isCases} to="/admin/cases">Cases</NavLink>
            <NavLink className="mx-2" to="/admin/sections">Content</NavLink>
            <NavLink className="mx-2" to="/admin/pages">Pages</NavLink>
            <NavLink className="mx-2" to="/admin/tests">Tests</NavLink>
            <NavLink className="mx-2" to="/admin/users">Users</NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
