import PropTypes from "prop-types";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import {valueFromTarget} from "lib/form";

function Answer({index, onChange, values}) {
  const id = index === 0 ? "record-answer" : `record-answer-${index}`;
  const value = values[index] || {};
  const onFieldChange = ({target}) => {
    const updatedValues = [...values];
    const updatedValue = {...value, [target.name]: valueFromTarget(target)};

    updatedValues.splice(index, 1, updatedValue);

    onChange({target: {name: "answers", value: updatedValues}});
  };
  const onRemove = () => {
    const updatedValues = [...values];

    updatedValues.splice(index, 1);

    onChange({target: {name: "answers", value: updatedValues}});
  };

  return (
    <div>
      <h3 className="flex items-center justify-between">
        <span>Answer {index + 1}</span>
        <button aria-label="Close" className="icon-red" onClick={onRemove} type="button">
          <i className="fas fa-window-close" />
        </button>
      </h3>
      <div className="mb-4">
        <Checkbox
          checked={value.correct || false}
          id={`${id}-correct`}
          label="Correct"
          name="correct"
          onChange={onFieldChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor={`${id}-content`}>Content</label>
        <ContentEditor
          id={`${id}-content`}
          name="content"
          onChange={onFieldChange}
          value={value.content || ""}
        />
      </div>
      <div className="mb-4">
        <label htmlFor={`${id}-rank`}>Rank</label>
        <input
          id={`${id}-rank`}
          name="rank"
          onChange={onFieldChange}
          placeholder="100"
          required={true}
          type="text"
          value={value.rank || ""}
        />
      </div>
    </div>
  );
}

Answer.propTypes = {
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default Answer;
