import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {setTag} from "app/actions/tag";
import {get as getTag} from "app/requests/public/tags";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";

export default function TagsShow() {
  const [{tag}, dispatch] = useAppContext();
  const {id} = useParams();

  useEffect(() => {
    if(tag && tag.id === id) { return; }

    getTag(id).then((data) => dispatch(setTag(data.tag)));
  }, [id]);

  if(!tag) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>{tag.name}</h1>
        <div className="custom-html">
          <Content className="mb-4" html={tag.content || "There is no content yet for this tag"} />
        </div>
      </div>
    </div>
  );
}
