import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyRecord} from "app/requests/admin/tags";
import Check from "components/helpers/check";
import {displayDate} from "lib/ext/string";
import useAppContext from "lib/hooks/use-app-context";

function AdminTagsListRow({onDestroy, record}) {
  const dispatch = useAppContext()[1];
  const onDelete = () => {
    if(!window.confirm("Are you sure you want to delete this tag?")) { return; }

    destroyRecord(record.id).then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "error"}));

      if(success) { onDestroy(record.id); }
    });
  };

  return (
    <tr>
      <td><Check checked={record.active} /></td>
      <td>{record.category.name}</td>
      <td>{record.name}</td>
      <td>{record.rank}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-blue" href={`/admin/tags/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-red" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminTagsListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    category: PropTypes.shape({name: PropTypes.string.isRequired}).isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired
  }).isRequired
};

export default AdminTagsListRow;
