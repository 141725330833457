import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getTags} from "app/requests/admin/tags";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import MultiSelectData from "components/helpers/form/multi-select-data";
import Notification from "components/helpers/notification";
import Loading from "components/pages/loading";

function AdminCasesForm({onChange, onSubmit, value}) {
  const [tags, setTags] = useState(null);

  useEffect(() => {
    getTags().then((data) => setTags(data.tags));
  }, []);

  if(!tags) { return <Loading />; }

  const options = tags.map((tag) => ({
    name: `${tag.category.name} - ${tag.name}`,
    value: tag.id.toString()
  }));

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", false)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Rectum? Darn near killed him!"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-number">Number</label>
          <input
            id="record-number"
            name="number"
            onChange={onChange}
            placeholder="Defaults to the next highest number"
            type="number"
            value={value("number")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-tag-ids">Tags</label>
          {tags.length === 0 ? (
            <Notification type="error">There are no tags available. You can create tags <a href="/admin/tags">here</a>.</Notification>
          ) : (
            <MultiSelectData
              fields={[
                {name: "number", placeholder: "Number (optional)", type: "number"},
                {name: "name", placeholder: "Name (optional)", type: "text"}
              ]}
              id="record-case-tags"
              name="case_tags"
              onChange={onChange}
              select={{name: "tag_id", options, placeholder: "Select Tags"}}
              value={value("case_tags") || []}
            />
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="record-answer-disabled">Answer</label>
          <ContentEditor
            id="record-answer"
            name="answer"
            onChange={onChange}
            value={value("answer")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-deep-dive-disabled">Deep Dive</label>
          <ContentEditor
            id="record-deep-dive"
            name="deep_dive"
            onChange={onChange}
            value={value("deep_dive")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-question-disabled">Question</label>
          <ContentEditor
            id="record-question"
            name="question"
            onChange={onChange}
            value={value("question")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-quick-take-disabled">Quick Take</label>
          <ContentEditor
            id="record-quick-take"
            name="quick_take"
            onChange={onChange}
            value={value("quick_take")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminCasesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminCasesForm;
