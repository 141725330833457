import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import CommentsContainer from "components/helpers/comments";
import Loading from "components/pages/loading";
import useUnknown from "lib/hooks/use-unknown";
import Links from "./links";

function Comments({id: _id}) {
  const params = useParams();
  const id = _id || +params.id;
  const unknown = useUnknown(id);

  if(!unknown) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>Comments</h1>
        <CommentsContainer record={unknown} />
        <Links />
      </div>
    </div>
  );
}

Comments.defaultProps = {id: null};
Comments.propTypes = {id: PropTypes.number};

export default Comments;
