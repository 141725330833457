import PropTypes from "prop-types";
import {useRouteMatch} from "react-router-dom";
import tomify from "app/assets/images/tomify.png";
import {createNotification} from "app/actions/notifications";
import {destroy as destroySession} from "app/requests/public/session";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

function NavLink({children, className, to, ...props}) {
  const match = useRouteMatch(to);
  const classes = [
    className,
    match && "active"
  ].filter(Boolean).join(" ");

  return <a className={classes} href={to} {...props}>{children}</a>;
}

NavLink.defaultProps = {className: ""};
NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired
};

function DefaultNavbar() {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex items-center justify-between">
          <a className="inline-block" href="/">
            <i className="block fas fa-spin fa-spinner fa-2x my-6" />
          </a>
          <div>
            <NavLink className="mx-2" to="/">Emergency Ultrasound Unknowns</NavLink>
            <NavLink className="mx-2" to="/session">Sign In</NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default function Navbar() {
  const [{app: {loading}, pages, user}, dispatch] = useAppContext();
  const logo = useContent("Navbar", "Logo");
  const onSignOut = () => {
    destroySession().then((data) => {
      if(data.success) {
        window.location.href = `/?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };

  if(loading) { return <DefaultNavbar />; }

  const casePages = pages.filter((page) => page.category_id);
  const defaultPages = pages.filter((page) => page.path !== "home")
    .filter((page) => !page.category_id)
    .filter((page) => page.template !== "Random")
    .filter((page) => !page.test_id);
  const randomPages = pages.filter((page) => page.template === "Random");
  const testPages = pages.filter((page) => page.test_id);

  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex items-center justify-between">
          <a className="inline-block" href="/">
            <img alt="Logo" src={logo || tomify} />
          </a>
          <div className="text-right">
            <div className="mt-4">
              <NavLink className="mx-2" to="/">Emergency Ultrasound Unknowns</NavLink>
              {user ? (
                <>
                  <NavLink className="mx-2" to="/profile">Profile</NavLink>
                  <button className="mx-2" onClick={onSignOut} type="button">Sign Out</button>
                </>
              ) : (
                <NavLink className="mx-2" to="/session">Sign In</NavLink>
              )}
            </div>
            <div className="mt-6">
              {defaultPages.map((page) => (
                <NavLink className="mx-2" key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
              ))}
            </div>
          </div>
        </div>
        {testPages.length > 0 && (
          <div className="mt-6 text-right">
            {testPages.map((page) => (
              <NavLink className="mx-2" key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
            ))}
          </div>
        )}
        {(randomPages.length > 0 || casePages.length > 0) && (
          <div className="flex items-center justify-between mt-6">
            {randomPages.map((page) => (
              <NavLink className="mx-2" key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
            ))}
            {casePages.map((page) => (
              <NavLink className="mx-2" key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}
