import PropTypes from "prop-types";
import {useState} from "react";
import Row from "./row";

function AdminTestQuestionsList({records: initialQuestions}) {
  const [records, setRecords] = useState(initialQuestions);

  if(records.length === 0) { return <div className="text-center">There are no questions</div>; }

  const onDestroy = (id) => { setRecords(records.filter((record) => record.id !== id)); };

  return (
    <div className="max-w-screen-lg mx-auto">
      <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Tag</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <Row key={record.id} onDestroy={onDestroy} record={record} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

AdminTestQuestionsList.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default AdminTestQuestionsList;
