import {SET_TAG} from "app/actions/tag";

export default function tag(state, action) {
  switch(action.type) {
    case SET_TAG:
      return action.tag ? {...action.tag} : null;
    default:
      return state;
  }
}
