import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getCategories} from "app/requests/admin/categories";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import Notification from "components/helpers/notification";
import Loading from "components/pages/loading";

function AdminTagsForm({onChange, onSubmit, value}) {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getCategories().then((data) => setCategories(data.categories));
  }, []);

  if(!categories) { return <Loading />; }

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", false)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-category-id">Category</label>
          {categories.length === 0 ? (
            <Notification type="error">There are no categories available. You must <a href="/admin/categories">create one</a> before creating a tag.</Notification>
          ) : (
            <select
              id="record-category-id"
              name="category_id"
              onChange={onChange}
              required={true}
              value={value("category_id")}
            >
              {!value("id") && <option value="">Select Category</option>}
              {categories.map(({id, name}) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="record-content-disabled">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Rectum? Darn near killed him!"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-rank">Rank</label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminTagsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminTagsForm;
