import PropTypes from "prop-types";
import Checkbox from "components/helpers/form/checkbox";
import File from "components/helpers/form/file";
import FormWithFiles from "components/helpers/form/with-files";

function AdminUsersForm({onChange, onSubmit, value}) {
  return (
    <div className="max-w-sm mx-auto">
      <FormWithFiles onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="record-first-name">First Name</label>
          <input
            id="record-first-name"
            name="first_name"
            onChange={onChange}
            placeholder="Clark"
            required={true}
            type="text"
            value={value("first_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-last-name">Last Name</label>
          <input
            id="record-last-name"
            name="last_name"
            onChange={onChange}
            placeholder="Kent"
            required={true}
            type="text"
            value={value("last_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-email">Email</label>
          <input
            id="record-email"
            name="email"
            onChange={onChange}
            placeholder="clarkkent@example.com"
            required={true}
            type="email"
            value={value("email")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-image">Image</label>
          <File id="record-image" name="image" onChange={onChange} />
          {value("image") && <img alt="Profile" src={value("image")} />}
        </div>
        <div className="mb-4">
          <Checkbox
            checked={value("admin", false)}
            id="record-admin"
            label="Admin"
            name="admin"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            checked={value("case_notifications", false)}
            id="record-case-notifications"
            label="Case Notifications"
            name="case_notifications"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            checked={value("daily_notifications", false)}
            id="record-daily-notifications"
            label="Daily Notifications"
            name="daily_notifications"
            onChange={onChange}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </FormWithFiles>
    </div>
  );
}

AdminUsersForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminUsersForm;
