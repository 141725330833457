import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyComment, get as getComments} from "app/requests/public/comments";
import {displayDateTime} from "lib/ext/string";
import useAppContext from "lib/hooks/use-app-context";
import New from "./new";

function Comments({record}) {
  const [{user}, dispatch] = useAppContext();
  const [comments, setComments] = useState(null);
  const onDelete = (id) => {
    if(!window.confirm("Are you sure you want to remove this comment?")) { return; }

    destroyComment(id).then(({comments: newComments, message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));

      if(success) { setComments(newComments); }
    });
  };

  useEffect(() => {
    getComments({id: record.id, type: record.type}).then((data) => setComments(data.comments));
  }, [record.id]);

  if(!comments) { return null; }

  return (
    <div className="mb-4">
      <New record={record} setComments={setComments} />
      {comments.map((comment) => (
        <div key={comment.id} className="mt-3 flex">
          <div className="pr-2">
            <img alt="Commenter" src={comment.image} width="60" />
          </div>
          <div className="flex-1">
            <div className="flex text-sm">
              <span className="flex-grow">By {comment.name} on {displayDateTime(comment.created_at)}</span>
              {user && (user.id === comment.user_id || user.admin) && (
                <button onClick={() => onDelete(comment.id)} type="button">x</button>
              )}
            </div>
            <div className="preserve-whitespace">{comment.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

Comments.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default Comments;
