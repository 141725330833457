import {useState} from "react";
import {useHistory} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {create as createRecord} from "app/requests/admin/tests";
import {withoutBlankValues} from "lib/ext/object";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";
import usePrompt from "lib/hooks/use-prompt";
import Form from "./form";

export default function AdminTestsNew() {
  const dispatch = useAppContext()[1];
  const history = useHistory();
  const [block, setBlock] = useState(false);
  const [record, setRecord] = useState({
    category_id: "",
    name: "",
    notify_admin: true,
    notify_users: true
  });
  const onChange = ({target}) => {
    setBlock(true);
    setRecord({...record, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    setBlock(false);
    createRecord({test: withoutBlankValues(record)}).then(({id, message, success}) => {
      if(success) {
        history.push(`/admin/tests/${id}`, {message, type: "success"});
      } else {
        dispatch(createNotification({content: message, type: "error"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [record]});

  usePrompt({when: block});

  return (
    <div className="container">
      <h1 className="text-center">New Test</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
