import PropTypes from "prop-types";
import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {create as createComment} from "app/requests/public/comments";
import Checkbox from "components/helpers/form/checkbox";
import useAppContext from "lib/hooks/use-app-context";
import Subscribe from "./subscribe";

function New({record, setComments}) {
  const [{user}, dispatch] = useAppContext();
  const [comment, setComment] = useState({
    commentable_id: record.id,
    commentable_type: record.type
  });
  const submit = (params) => {
    if(!comment.text) { return; }
    if(!user && !window.confirm("Are you sure you want to comment anonymously?")) { return; }

    createComment({comment: params}).then(({comments, message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));

      if(success) {
        setComment({
          commentable_id: record.id,
          commentable_type: record.type
        });
        setComments(comments);
      }
    });
  };
  const onChange = ({target: {checked, name, type, value}}) => (
    setComment({...comment, [name]: type === "checkbox" ? checked : value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    submit(comment);
  };

  return (
    <form onSubmit={onSubmit}>
      <textarea
        className="flex-auto mb-1 rounded-r-none"
        name="text"
        onChange={onChange}
        placeholder="What do you think?"
        value={comment.text || ""}
      />
      {user ? (
        <div className="flex justify-between">
          <Checkbox
            checked={comment.anonymous || false}
            id="comment-anonymous"
            label="Post Anonymously?"
            name="anonymous"
            onChange={onChange}
          />
          <button className="btn btn-blue" type="submit">Comment</button>
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="btn-group block text-center">
            <a className="btn btn-blue" href="/session" target="_blank">Sign In</a>
            <a className="btn btn-green" href="/register" target="_blank">Sign Up</a>
          </div>
          <button className="btn btn-dark-gray" type="submit">Comment</button>
        </div>
      )}
      <Subscribe record={record} />
    </form>
  );
}

New.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  setComments: PropTypes.func.isRequired
};

export default New;
