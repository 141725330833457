/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import Fields from "./fields";

function MultiSelectWithFields({value, ...props}) {
  const values = [...value];
  const lastValue = (values[values.length - 1] || {})[props.select.name];

  if(values.length === 0 || lastValue) { values.push({}); }

  return (
    <>
      {values.map((_, index) => (
        <Fields
          key={index}
          index={index}
          values={values}
          {...props}
        />
      ))}
    </>
  );
}

MultiSelectWithFields.propTypes = {
  select: PropTypes.shape({name: PropTypes.string.isRequired}).isRequired,
  value: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default MultiSelectWithFields;
