import headers from "app/requests/headers";
import {toQueryString} from "lib/ext/object";

export const create = (params) => (
  fetch("/api/public/comments", {
    body: JSON.stringify(params),
    headers: headers(),
    method: "POST"
  }).then((response) => response.json())
);

export const destroy = (id) => (
  fetch(`/api/public/comments/${id}`, {
    headers: headers(),
    method: "DELETE"
  }).then((response) => response.json())
);

export const get = (params) => (
  fetch(`/api/public/comments?${toQueryString(params || {})}`, {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);

export const subscribe = (params) => (
  fetch(`/api/public/comments/subscribe`, {
    body: JSON.stringify(params),
    headers: headers(),
    method: "POST"
  }).then((response) => response.json())
);

export const unsubscribe = (params) => (
  fetch(`/api/public/comments/unsubscribe`, {
    body: JSON.stringify(params),
    headers: headers(),
    method: "POST"
  }).then((response) => response.json())
);
