import headers from "app/requests/headers";
import {toQueryString} from "lib/ext/object";

export const create = (params) => (
  fetch("/api/public/user", {
    body: JSON.stringify(params),
    headers: headers(),
    method: "POST"
  }).then((response) => response.json())
);

export const getStatus = (params) => (
  fetch(`/api/public/user/status?${toQueryString(params || {})}`, {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);

export const update = (params) => (
  fetch("/api/public/user", {
    body: JSON.stringify(params),
    headers: headers(),
    method: "PUT"
  }).then((response) => response.json())
);
