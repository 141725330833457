import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyRecord} from "app/requests/admin/cases";
import Check from "components/helpers/check";
import {displayDate} from "lib/ext/string";
import useAppContext from "lib/hooks/use-app-context";

function AdminCasesListRow({onDestroy, record}) {
  const dispatch = useAppContext()[1];
  const onDelete = () => {
    if(!window.confirm("Are you sure you want to delete this case?")) { return; }

    destroyRecord(record.id).then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "error"}));

      if(success) { onDestroy(record.id); }
    });
  };

  return (
    <tr>
      <td><Check checked={record.active} /></td>
      <td>{record.name}</td>
      <td>{record.number}</td>
      <td className="text-left">
        {record.tags.map((tag) => (
          <div key={tag.id}>{`${tag.category.name} - ${tag.name}`}</div>
        ))}
      </td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-green" href={`/unknowns/${record.id}/question`} rel="noopener noreferrer" target="_blank">View</a>
          <a className="btn btn-sm btn-blue" href={`/admin/cases/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-red" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminCasesListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({name: PropTypes.string.isRequired}),
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

export default AdminCasesListRow;
