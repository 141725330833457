import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";

export default function AdminCasesListLinks() {
  const [classes, setClasses] = useState({});
  const location = useLocation();

  useEffect(() => {
    const updatedClasses = [];

    ["cases", "categories", "tags"].forEach((page) => {
      const type = location.pathname.endsWith(page) ? "btn-blue" : "btn-outline-blue";

      updatedClasses[page] = `btn btn-sm ${type}`;
    });

    setClasses(updatedClasses);
  }, [location]);

  return (
    <div className="btn-group">
      <Link className={classes.cases} to="/admin/cases">Cases</Link>
      <Link className={classes.categories} to="/admin/categories">Categories</Link>
      <Link className={classes.tags} to="/admin/tags">Tags</Link>
    </div>
  );
}
