import {useEffect} from "react";
import {setCategory} from "app/actions/category";
import {get as getCategory} from "app/requests/public/categories";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import Groups from "./groups";
import List from "./list";

export default function Cases() {
  const [{category, page}, dispatch] = useAppContext();

  useEffect(() => {
    getCategory(page.category_id).then((data) => dispatch(setCategory(data.category)));
  }, [page.category_id]);

  if(!category) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>{category.name}</h1>
        <div className="custom-html">
          <Content className="mb-4" html={page.content} />
          {category.layout === "List" ? <List /> : <Groups />}
        </div>
      </div>
    </div>
  );
}
