import PropTypes from "prop-types";

function AdminCategoriesForm({onChange, onSubmit, value}) {
  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="record-display">Display</label>
          <select
            id="record-display"
            name="display"
            onChange={onChange}
            required={true}
            value={value("display")}
          >
            {["Name", "Number"].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-layout">Layout</label>
          <select
            id="record-layout"
            name="layout"
            onChange={onChange}
            required={true}
            value={value("layout")}
          >
            {["Groups", "List"].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="The Great Unknown"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminCategoriesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminCategoriesForm;
