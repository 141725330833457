import {Link} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";

export default function Links() {
  const [{unknown, user}] = useAppContext();

  if(!unknown) { return null; }

  return (
    <>
      <div className="btn-group block text-center">
        <Link className="btn btn-sm btn-dark-gray" to={`/unknowns/${unknown.id}/question`}>Question</Link>
        <Link className="btn btn-sm btn-blue" to={`/unknowns/${unknown.id}/answer`}>Answer</Link>
        <Link className="btn btn-sm btn-yellow" to={`/unknowns/${unknown.id}/quick-take`}>Quick Take</Link>
        <Link className="btn btn-sm btn-green" to={`/unknowns/${unknown.id}/deep-dive`}>Deep Dive</Link>
        <Link className="btn btn-sm btn-dark-gray" to={`/unknowns/${unknown.id}/comments`}>Comments</Link>
      </div>
      {user?.admin && (
        <div className="btn-group block mt-3 text-center">
          <Link className="btn btn-sm btn-dark-gray" to={`/admin/cases/${unknown.id}`}>Edit</Link>
        </div>
      )}
    </>
  );
}
