import {useEffect, useLayoutEffect} from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {setApp} from "app/actions/app";
import {get as fetchApp} from "app/requests/app";
import Switch from "components/helpers/better-switch";
import AdminNavbar from "components/layout/admin/navbar";
import Analytics from "components/layout/analytics";
import ErrorBoundary from "components/layout/error-boundary";
import Notifications from "components/layout/notifications";
import Navbar from "components/layout/public/navbar";
import * as Pages from "components/pages";
import useAppContext from "lib/hooks/use-app-context";

export default function Router() {
  const [{app: {loading}, pages, user}, dispatch] = useAppContext();
  const location = useLocation();

  useEffect(() => {
    fetchApp().then((app) => dispatch(setApp({loading: false, ...app})));
  }, []);

  useLayoutEffect(() => { window.scrollTo(0, 0); }, [location.pathname]);

  return (
    <div className="flex flex-col h-screen">
      {user && user.admin && <AdminNavbar />}
      <Navbar />
      <Notifications />
      <main>
        <ErrorBoundary>
          {loading ? (
            <Pages.Loading />
          ) : (
            <Switch>
              <Route component={Pages.Public.Template} exact={true} path="/" />
              <Route component={Pages.Public.Tags.Show} path="/tags/:id" />
              <Route component={Pages.Public.Tests.Show} path="/tests/:id" />
              <Route component={Pages.Public.Unknowns.Answer} path="/unknowns/:id/answer" />
              <Route component={Pages.Public.Unknowns.Comments} path="/unknowns/:id/comments" />
              <Route component={Pages.Public.Unknowns.DeepDive} path="/unknowns/:id/deep-dive" />
              <Route component={Pages.Public.Unknowns.Question} path="/unknowns/:id/question" />
              <Route component={Pages.Public.Unknowns.QuickTake} path="/unknowns/:id/quick-take" />
              <Redirect from="/unknowns/:id" to="/unknowns/:id/question" />
              {user ? (
                <>
                  <Route component={Pages.Public.Profile} path="/profile" />
                  <Redirect from="/forgot-password" to="/profile" />
                  <Redirect from="/register" to="/session" />
                  <Redirect from="/session" to="/profile" />
                </>
              ) : (
                <>
                  <Route component={Pages.Public.ForgotPassword} path="/forgot-password" />
                  <Route component={Pages.Public.Register} path="/register" />
                  <Route component={Pages.Public.Session} path="/session" />
                  <Redirect from="/profile" to="/session" />
                </>
              )}
              {user && user.admin ? (
                <>
                  <Route component={Pages.Public.Categories.Show} path="/categories/:id" />
                  <Redirect exact={true} from="/admin" to="/admin/sections" />
                </>
              ) : (
                <>
                  <Redirect from="/admin" to="/profile" />
                  <Redirect from="/categories" to="/" />
                </>
              )}
              <Route component={Pages.Admin.Cases.New} path="/admin/cases/new" />
              <Route component={Pages.Admin.Cases.Edit} path="/admin/cases/:id" />
              <Route component={Pages.Admin.Cases.List} path="/admin/cases" />
              <Route component={Pages.Admin.Categories.New} path="/admin/categories/new" />
              <Route component={Pages.Admin.Categories.Edit} path="/admin/categories/:id" />
              <Route component={Pages.Admin.Categories.List} path="/admin/categories" />
              <Route component={Pages.Admin.Pages.New} path="/admin/pages/new" />
              <Route component={Pages.Admin.Pages.Edit} path="/admin/pages/:id" />
              <Route component={Pages.Admin.Pages.List} path="/admin/pages" />
              <Route component={Pages.Admin.Sections.Edit} path="/admin/sections/:id" />
              <Route component={Pages.Admin.Sections.List} path="/admin/sections" />
              <Route component={Pages.Admin.Tags.New} path="/admin/tags/new" />
              <Route component={Pages.Admin.Tags.Edit} path="/admin/tags/:id" />
              <Route component={Pages.Admin.Tags.List} path="/admin/tags" />
              <Route component={Pages.Admin.Questions.Edit} path="/admin/questions/:id" />
              <Route component={Pages.Admin.Questions.New} path="/admin/tests/:testID/questions/new" />
              <Route component={Pages.Admin.Tests.New} path="/admin/tests/new" />
              <Route component={Pages.Admin.Tests.Edit} path="/admin/tests/:id/edit" />
              <Route component={Pages.Admin.Tests.Show} path="/admin/tests/:id" />
              <Route component={Pages.Admin.Tests.List} path="/admin/tests" />
              <Route component={Pages.Admin.Users.New} path="/admin/users/new" />
              <Route component={Pages.Admin.Users.Edit} path="/admin/users/:id" />
              <Route component={Pages.Admin.Users.List} path="/admin/users" />
              <Redirect from="/home" to="/" />
              {pages.map((page) => (
                <Route key={page.path} component={Pages.Public.Template} exact={true} path={`/${page.path}`} />
              ))}
              {!loading && <Route component={Pages.NotFound} path="/" />}
            </Switch>
          )}
        </ErrorBoundary>
      </main>
      <Analytics />
    </div>
  );
}
